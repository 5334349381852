<template>
  <div class="IntelligentWritingAI">
    <div class="home_header"></div>
    {{urlArr}}
    <div class="IntelligentWritingAI_mian">
      <el-upload
        :key="index"
        v-for="(item,index) in num"
        drag
        :headers="headers"
        action="/uploadfile/"
        multiple
        class="upload-drag"
        :auto-upload="true"
        :on-change="uploadChangeFn"
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
        :on-success="(e) => uploadFn(e,index)">
        <div v-if="urlArr[index]">
          <p>{{urlArr[index].filename}}</p>
        </div>
        <div v-else>
          <i class="el-icon-plus"></i>
        </div>
      </el-upload>

      <el-button @click="add()" type="primary">添加<i class="el-icon-document-add"></i></el-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AI",
    data(){
      return {
        //上传图片header
        headers: {
          'TENANT-ID': '4'
        },
        urlArr:[],
        num: 3
      }
    },
    methods:{
      add(){
        this.num++;
      },
      uploadChangeFn(file, fileList) {
        // let imgSize = Number(file.size / 1024 / 1024);
        //
        // if (imgSize > 3) {
        //   this.$message('文件大小不能超过3MB，请重新上传');
        //   this.materialList[scope.$index].fileList.splice(scope.index, 1);
        //   return;
        // }
      },
      //新增监控图片上传前的钩子
      beforeAvatarUpload(file) {
        const isLt5M = file.size < 1024 * 1024 * 5
        if (!isLt5M) {
          this.$message.error('上传图片大小不能超过 5MB!')
          return false
        }
        return true
      },
      //新增监控上传图片成功
      uploadFn(response,val) {
        if (response.message == "success"){
          this.urlArr[2] = response;
        }
      },
    }
  }
</script>

<style lang="less" scoped>
  .IntelligentWritingAI {
    .home_header {
      height: 90px;
    }
  }
</style>
